import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './Car.css';
import { langs } from '../lan';
import { useSelector } from 'react-redux';

export default function Car() {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { id } = useParams();
    const lang = useSelector((state)=>state.lang.lang)

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const response = await axios.get(`https://luxwheelsrent.com/api/cars/${id}`);
                setData(response.data);
                setLoading(false);
            } catch (err) {
                setError(err);
                setLoading(false);
            }
        };

        fetchData();
    }, [id]);

    const getimagename = (n) => {
        if (!data || !data.Picture) {
            return '';
        }
        const full = data.Picture.split('.');
        full[0] = full[0] + "_" + n;
        return full.join('.');
    };

    if (loading) {
        return (
            <section id="services" className="bg-zinc-900 text-white py-5">
                <div className="flex flex-col min-h-screen">
                    <div className="flex-1 p-2">
                        <div className="px-7 grid grid-cols-1 sm:grid-cols-2 space-x-4 sm:space-y-7">
                            <div className="bg-zinc-800 rounded-md content-stretch p-5 flex flex-col justify-between">
                                <div className="flex space-x-5">
                                    <p className="font-bold text-3xl">Loading...</p>
                                </div>
                                <br/>
                                <p className="text-gray-500">Loading...</p>
                                <div className="grid grid-cols-2 sm:grid-cols-4 lg:grid-cols-6 gap-4 py-6">
                                    <div className="text-gray-200">Type Car</div>
                                    <div className="text-gray-400 font-semibold">Loading..</div>
                                    <div className="text-gray-200">Capacity</div>
                                    <div className="text-gray-400 font-semibold">Loading...</div>
                                    <div className="text-gray-200">Gasoling</div>
                                    <div className="text-gray-400 font-semibold">Loading...</div>
                                    <div className="text-gray-200">Steering</div>
                                    <div className="text-gray-400 font-semibold">Loading...</div>
                                </div>
                                <div className="mt-auto">
                                    <div className="flex justify-between items-center">
                                        <div className="flex items-end space-x-1">
                                            <p className="font-semibold text-2xl text-gray-400">Price: </p>
                                            <p className="font-bold text-2xl">Loading...</p>
                                            <p className="font-semibold text-gray-400">/ days</p>
                                        </div>
                                        <div>
                                            <button className="bg-yellow-600 hover:bg-yellow-400 p-2 px-5 rounded text-white font-semibold">Rent Now</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }

    if (error) {
        return <div className="text-red-500">Error: {error.message}</div>;
    }

    return (
        <section id="services" className="bg-zinc-900 text-white py-5">
            <div className="flex flex-col min-h-screen">
                <div className="flex-1 p-2">
                    <div className="px-7 grid grid-cols-1 sm:grid-cols-2 space-x-4 sm:space-y-7">
                        <div className="w-fit p-7">
                            <div className="bg-zinc-800 h-min rounded-md p-5 text-white overflow-hidden shadow-md">
                                <div className="flex justify-center">
                                    <img src={`https://luxwheelsrent.com/pictures/${data.Picture}`} className="rounded-md hover:scale-110 transition-transform duration-300" alt="Car"/>
                                </div>
                            </div>
                            {/* <div className="pt-4 space-x-6 grid grid-cols-3 justify-between items-center">
                                <div className="overflow-hidden rounded-md">
                                    <img src={`https://luxwheelsrent.com/pictures/${getimagename(1)}`} height="100" className="hover:scale-125 transition-transform duration-300" alt="Car Image"/>
                                </div>
                                <div className="overflow-hidden rounded-md">
                                    <img src={`https://luxwheelsrent.com/pictures/${getimagename(2)}`} height="100" className="hover:scale-125 transition-transform duration-300" alt="Car Image"/>
                                </div>
                                <div className="overflow-hidden rounded-md">
                                    <img src={`https://luxwheelsrent.com/pictures/${getimagename(3)}`} height="100" className="hover:scale-125 transition-transform duration-300" alt="Car Image"/>
                                </div>
                            </div> */}
                        </div>
                        <div className="bg-zinc-800 rounded-md content-stretch p-5 flex flex-col justify-between">
                            <div className="flex space-x-5">
                                <p className="font-bold text-3xl">{data.Name}</p>
                            </div>
                            <br/>
                            <p className="text-gray-500">{data.description}</p>
                            <div className="grid grid-cols-2 sm:grid-cols-4 lg:grid-cols-6 gap-4 py-6">
                                <div className="text-gray-200">Model:</div>
                                <div className="text-gray-300 font-bold">{data.Model}</div>
                                <div className="text-gray-200">{langs[lang].type}</div>
                                <div className="text-gray-400 font-semibold">{data.TypeCar}</div>
                                <div className="text-gray-200">{langs[lang].cap}</div>
                                <div className="text-gray-400 font-semibold">{data.Capacity}</div>
                                <div className="text-gray-200">{langs[lang].gaso}</div>
                                <div className="text-gray-400 font-semibold">{data.Gas} L</div>
                                <div className="text-gray-200">{langs[lang].steer}</div>
                                <div className="text-gray-400 font-semibold">{data.Steer}</div>
                                <div className="text-gray-200">{langs[lang].color}:</div>
                                <div 
                                    className="w-8 h-8 rounded-md border" 
                                    style={{ backgroundColor: data.Color, borderColor: data.Color }}
                                ></div>
                            </div>
                            <div className="mt-auto">
                                <div className="flex justify-between items-center">
                                    {/* <div className="flex items-end space-x-1">
                                        <p className="font-semibold text-2xl text-gray-400">{langs[lang].price} </p>
                                        <p className="font-bold text-2xl">${data.Price}</p>
                                        <p className="font-semibold text-gray-400">/ {langs[lang].day}</p>
                                    </div> */}
                                    <div>
                                        <button className="bg-yellow-600 hover:bg-yellow-400 p-2 px-5 rounded text-white font-semibold">{langs[lang].rentnow}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
